import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { color, mq, fontFamily, widthFromGrid } from "../../utils/styles"

type Props = {
  title: string
}
const SubpageTitle: React.FC<Props> = ({ children, title }) => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const animations = setAnimation()

    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: { kill(): any }[] = []

    if (document.querySelector(".subpage_title_bg")) {
      const bgTl = gsap.fromTo(
        ".subpage_title_bg",
        {
          y: "0%",
        },
        {
          y: "30%",
          scrollTrigger: {
            trigger: ".subpage_title_bg",
            start: "top center",
            end: "bottom top",
            scrub: true,
          },
        }
      )
      animations.push(bgTl)
    }

    const barTl = gsap.fromTo(
      ".subpage_title_bar",
      {
        scaleX: 0,
        transformOrigin: "right center",
      },
      {
        scaleX: 1,
        duration: 1.5,
        ease: "power2",
        scrollTrigger: {
          trigger: ".subpage_title_bar",
          start: "top center",
        },
      }
    )
    animations.push(barTl)

    return animations
  }


  return (
    <div
      css={css`
        position: relative;
        display: grid;
        overflow: hidden;
        background: ${color.baseBg};
        ${mq.pc(css`
          margin-top: 157px;
          height: 320px;
          grid-template-columns: 1fr ${widthFromGrid()}px 1fr;
        `)}
        ${mq.sp(css`
          margin-top: 56px;
          height: 25.00vw;
          grid-template-columns: 1fr 93% 1fr;
        `)}
        .subpage_title_bg {
          position: absolute;
          opacity: 0.4;
          inset: -50% 0 0;
        }
      `}
    >
      {children}
      <h1
        css={css`
          position: relative;
          color: ${color.white};
          font-family: ${fontFamily.serif};
          line-height: 1.6;
          grid-column: 2 / -1;
          display: flex;
          align-items: center;
          ${mq.pc(css`
            font-size: 5.6rem;
          `)}
          ${mq.sp(css`
            font-size: 2.4rem;
          `)}
        `}
      >
        {title}
        <div
          className="subpage_title_bar"
          css={css`
            height: 1px;
            flex: 1;
            margin-left: 24px;
            background: ${color.pink};
          `}
        />
      </h1>
    </div>
  )
}

export default SubpageTitle
